import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../components/common/Breadcrumb'
import surface from "../../Assets/surface.svg";
import { ColorRing } from 'react-loader-spinner'



const RateCalculator = () => {
    const [rateDetails, setRateDetails] = useState({ pickupPincode: '', deliveryPincode: '', weight: 0, length: 0, breadth: 0, height: 0, productAmount: 0, orderType: "", pickupCity: '', pickupState: '', deliveryCity: '', deliveryState: '', chargedWeight: 0 })
    const [courierCompanyDetails, setCourierCompanyDetails] = useState([])
    const [loading, setLoading] = useState(false)

    const changeDetails = (e) => {
        setRateDetails({ ...rateDetails, [e.target.name]: e.target.value })
    }

    const calculateRate = async (e) => {
        e.preventDefault()
        setLoading(true)
        const response = await fetch('https://api.couriero.in/search-courier-companies/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                pickupPincode: rateDetails.pickupPincode,
                deliveryPincode: rateDetails.deliveryPincode,
                weight: rateDetails.chargedWeight * 1000,
                orderType: rateDetails.orderType,
                amount: rateDetails.productAmount,
            })
        })

        const result = await response.json()

        if (result.status !== 'error') {
            setCourierCompanyDetails(result)
        } else {
            setCourierCompanyDetails([])
        }
        setLoading(false)

        console.log('all companies are', courierCompanyDetails)

        console.log({
            pickupPincode: rateDetails.pickupPincode,
            deliveryPincode: rateDetails.deliveryPincode,
            weight: rateDetails.chargedWeight * 1000,
            orderType: rateDetails.orderType,
            amount: rateDetails.productAmount
        })


    }

    useEffect(() => {
        const checkPickupPincode = async () => {
            if (rateDetails.pickupPincode.length === 6) {
                const response = await fetch(`https://api.data.gov.in/resource/6176ee09-3d56-4a3b-8115-21841576b2f6?api-key=579b464db66ec23bdd0000014dc378d998af476b665db471066c40ec&format=json&filters%5Bpincode%5D=${rateDetails.pickupPincode}`, {
                    method: 'GET'
                })
                const result = await response.json()
                console.log(result[0])

                if (result.count >= 1) {
                    setRateDetails({ ...rateDetails, pickupCity: result.records[0].taluk, pickupState: result.records[0].statename })
                }
            } else {
                setRateDetails({ ...rateDetails, pickupCity: '', pickupState: '' })
            }
        }
        checkPickupPincode()
        // eslint-disable-next-line
    }, [rateDetails.pickupPincode])

    useEffect(() => {
        const checkDeliveryPincode = async () => {
            if (rateDetails.deliveryPincode.length === 6) {
                const response = await fetch(`https://api.data.gov.in/resource/6176ee09-3d56-4a3b-8115-21841576b2f6?api-key=579b464db66ec23bdd0000014dc378d998af476b665db471066c40ec&format=json&filters%5Bpincode%5D=${rateDetails.deliveryPincode}`, {
                    method: 'GET'
                })
                const result = await response.json()
                console.log(result[0])

                if (result.count >= 1) {
                    setRateDetails({ ...rateDetails, deliveryCity: result.records[0].taluk, deliveryState: result.records[0].statename })
                }
            } else {
                setRateDetails({ ...rateDetails, deliveryCity: '', deliveryState: '' })
            }
        }
        checkDeliveryPincode()
        // eslint-disable-next-line
    }, [rateDetails.deliveryPincode])

    const weightOnChange = (e) => {
        calculateChargedWeight()
        changeDetails(e)

    }

    useEffect(() => {
        calculateChargedWeight()
        // eslint-disable-next-line
    }, [rateDetails.length, rateDetails.breadth, rateDetails.height, rateDetails.weight])



    const calculateChargedWeight = () => {
        const volWeight = (rateDetails.length * rateDetails.breadth * rateDetails.height) / 5000

        if (rateDetails.weight > volWeight) {
            setRateDetails({ ...rateDetails, chargedWeight: rateDetails.weight })
        } else {
            setRateDetails({ ...rateDetails, chargedWeight: volWeight })
        }
    }





    return (
        <React.Fragment>
            <div className='flex flex-col mb-12 w-full overflow-y-auto text-gray-600'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="Shipping Charge Calculator" title="Billing" />
                </div>
                <div className='flex flex-col my-10 mx-4'>
                    <div className='flex flow-row'>
                        <div className='w-1/2 border border-blue-800 shadow-2xl flex flex-col rounded-2xl mx-2'>
                            <div className='border-b-2 px-2 my-2 mx-2 py-2'>
                                <p className='font-bold text-base text-center'>Domestic Shipments Rates</p>
                            </div>
                            <div>
                                <form onSubmit={calculateRate}>
                                    <div className='flex flex-col'>
                                        <div className='flex flex-row mx-6 justify-center' >
                                            <div className='flex flex-col'>
                                                <div className='mx-2'>
                                                    <h3 className='text-xs font-bold mx-2 my-2'>Pickup Pincode</h3>
                                                    <input
                                                        type='text'
                                                        className='text-xs border-2 rounded-2xl py-1 px-6'
                                                        id='pickupPincode'
                                                        name='pickupPincode'
                                                        placeholder='Pickup Pincode'
                                                        onChange={changeDetails}
                                                        maxLength={6}
                                                        value={rateDetails.pickupPincode}
                                                        autoComplete='off'
                                                        required
                                                    />
                                                    <div className={`flex flex-row items-center mx-2 ${rateDetails.pickupPincode.length === 0 ? 'hidden' : 'block'}`}>
                                                        <i className={`fa-solid fa-circle-dot text-${rateDetails.pickupCity.length === 0 ? 'red' : 'green'}-500 text-xs`}></i>
                                                        <h3 className='text-xs mx-2 my-1'>{rateDetails.pickupCity.length === 0 ? 'Enter correct pincode' : `${rateDetails.pickupCity}, ${rateDetails.pickupState}`}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-full h-1 bg-blue-300 my-11'></div>
                                            <div className='flex flex-col'>
                                                <div className='mx-2'>
                                                    <h3 className='text-xs font-bold mx-2 my-2'>Delivery Pincode</h3>
                                                    <input
                                                        type='text'
                                                        className='text-xs border-2 rounded-2xl py-1 px-6'
                                                        id='deliveryPincode'
                                                        name='deliveryPincode'
                                                        placeholder='Delivery Pincode'
                                                        onChange={changeDetails}
                                                        maxLength={6}
                                                        value={rateDetails.deliveryPincode}
                                                        autoComplete='off'
                                                        required
                                                    />
                                                    <div className={`flex flex-row items-center mx-2 ${rateDetails.deliveryPincode.length === 0 ? 'hidden' : 'block'}`}>
                                                        <i className={`fa-solid fa-circle-dot text-${rateDetails.deliveryCity.length === 0 ? 'red' : 'green'}-500 text-xs`}></i>
                                                        <h3 className='text-xs mx-2 my-1'>{rateDetails.deliveryCity.length === 0 ? 'Enter correct pincode' : `${rateDetails.deliveryCity}, ${rateDetails.deliveryState}`}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex flex-row justify-center'>
                                            <div className='flex flex-col mr-5'>
                                                <h3 className='text-xs font-bold mx-2 my-2'>Weight (in kg)</h3>
                                                <input
                                                    type="number"
                                                    className="px-3 py-1 text-xs border-2 rounded-2xl"
                                                    id="weight"
                                                    name='weight'
                                                    placeholder='Weight'
                                                    onChange={weightOnChange}
                                                    value={rateDetails.weight}
                                                    autoComplete='off'
                                                    required
                                                />
                                            </div>
                                            <div className='flex flex-col ml-5'>
                                                <h3 className='text-xs font-bold mx-2 my-2'>Dimensions (in cm)</h3>
                                                <div className='flex flex-row items-center'>
                                                    <input
                                                        type="number"
                                                        className="px-3 py-1 text-xs border-2 rounded-2xl w-20"
                                                        id="length"
                                                        name='length'
                                                        placeholder='Length'
                                                        onChange={weightOnChange}
                                                        value={rateDetails.length}
                                                        required
                                                    />
                                                    <h3 className='mx-2 text-xs font-medium'>X</h3>
                                                    <input
                                                        type="number"
                                                        className="px-3 py-1 text-xs border-2 rounded-2xl w-20"
                                                        id="breadth"
                                                        name='breadth'
                                                        placeholder='Breadth'
                                                        onChange={weightOnChange}
                                                        value={rateDetails.breadth}
                                                        required
                                                    />
                                                    <h3 className='mx-2 text-xs font-medium'>X</h3>
                                                    <input
                                                        type="number"
                                                        className="px-3 py-1 text-xs border-2 rounded-2xl w-20"
                                                        id="height"
                                                        name='height'
                                                        placeholder='Height'
                                                        onChange={weightOnChange}
                                                        value={rateDetails.height}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex flex-row mx-6 my-8 justify-between items-center' >
                                            <div className='flex flex-col mx-2'>
                                                <h3 className='text-xs font-bold mx-2 my-2'>Product Amount</h3>
                                                <input
                                                    type='number'
                                                    className='text-xs border-2 rounded-2xl py-1 px-3'
                                                    id='productAmount'
                                                    name='productAmount'
                                                    placeholder='Product Amount'
                                                    onChange={changeDetails}
                                                    value={rateDetails.productAmount}
                                                    required
                                                />
                                            </div>
                                            <div className='flex flex-col'>
                                                <h3 className='text-xs font-bold mx-2 my-2'>Order Type</h3>
                                                <div className='items-center flex flex-row'>
                                                    <div className='flex flex-row mx-2'>
                                                        <input
                                                            type="radio"
                                                            id="cod"
                                                            className="rounded-full border border-solid border-gray-300 cursor-pointer"
                                                            name='orderType'
                                                            value='cod'
                                                            onChange={changeDetails}
                                                            required
                                                        />
                                                        <label
                                                            className="cursor-pointer text-xs mx-2"
                                                            htmlFor="cod"
                                                        >
                                                            COD
                                                        </label>
                                                    </div>
                                                    <div className='flex flex-row mx-2'>
                                                        <input
                                                            type="radio"
                                                            id="prepaid"
                                                            className=" rounded-full border border-solid border-gray-300 cursor-pointer"
                                                            name='orderType'
                                                            value='prepaid'
                                                            onChange={changeDetails}
                                                            required
                                                        />
                                                        <label
                                                            className="  cursor-pointer text-xs mx-2"
                                                            htmlFor="prepaid"
                                                        >
                                                            Prepaid
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex justify-center mb-4'>
                                            <button
                                                className='bg-blue-500 rounded-2xl px-4 text-white cursor-pointer font-medium disabled:opacity-50'
                                                type='submit'
                                                disabled={!rateDetails.pickupCity || !rateDetails.deliveryCity || rateDetails.weight === 0 || !rateDetails.weight}
                                            >
                                                Calculate Rate
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {
                            loading && (
                                <div className='w-1/2 flex items-center justify-center'>
                                    <ColorRing
                                        visible={true}
                                        height="80"
                                        width="80"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                    />
                                </div>
                            )
                        }
                        {
                            !loading && courierCompanyDetails.length !== 0 && (
                                <div className='w-1/2 flex flex-col rounded-2xl mx-4 h-full'>
                                    {courierCompanyDetails.map((element) => {
                                        return (
                                            <div className='flex flex-col border h-32 border-orange-400 shadow-2xl rounded-2xl mb-4' key={element.id}>
                                                <div className='flex flex-row h-full'>
                                                    <div className='flex flex-col items-center justify-center w-4/12'>
                                                        <img
                                                            className="rounded cursor-pointer align-middle block py-4"
                                                            alt={element.courierName}
                                                            width="120"
                                                            height="20"
                                                            src={element.logo}
                                                        />
                                                        <h3 className='text-sm font-bold text-blue-600'>{element.courierName}</h3>
                                                    </div>
                                                    <div className='border-l-2 flex flex-col w-8/12'>
                                                        <div className='flex border-b-2 justify-between h-24 items-end border-dotted'>
                                                            <img
                                                                className="rounded mx-4"
                                                                alt="Surface"
                                                                width="48"
                                                                src={surface}
                                                            />
                                                            <h1 className='text-3xl font-medium mx-4'>₹ {element.chargeForward + element.codCharge}</h1>

                                                        </div>
                                                        <div className='h-1/12 flex flex-col px-2'>
                                                            <h3 className='text-xs font-medium'><span className='font-bold'>Pickup Date - </span>{element.pickupDate}</h3>
                                                            <h3 className='text-xs font-medium'><span className='font-bold'>Delivery Date - </span>{element.deliveryDate}</h3>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default RateCalculator
